
/********************************************************
*   COLORS
*******************************/

html{

    background-color: $c-white;

    body{
        background-color: $c-white;
        color: $c-black;
        header{
            border-bottom: .1rem solid $c-black;
            &.no-border{
                border: none;
            }
        }
        footer{
            border-top: .1rem solid $c-black;
        }
    }

    &.presentation{

        background-color: $c-black;

        body{
            background-color: $c-black;
            color: $c-white;

            header{
                border-bottom: none;
                svg{
                    path{
                        fill:$c-white;
                    }
                }
                div.close{
                    svg{
                        path{
                            stroke:#fff;
                            stroke-width:3;
                            stroke-miterlimit:10;
                        }
                    }
                }
            }

        }

    }

}
