/********************************************************
*   HELPERS
*******************************/
span.clear {
    display: block;
    clear: both;
    width: 1px;
    height: 0.001%;
    font-size: 0px;
    line-height: 0px;
    float: none !important;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.full-centered{
    position: absolute !important;
    top:50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

div.table{
    display:table;
    width:100%;
    height:100%;
    table-layout: fixed;

    div.cell{
        display:table-cell;
        width:100%;
        height:100%;
        vertical-align:middle;
        /* text-align:center; */
        position: relative;
    }

}

.videoWrapper {

    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe, video, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.is-hidden-desktop{
    display: block;
}
.is-hidden-mobile{
    display: none;
}

.no-margin{
    margin:0 !important;
}

.square{
    position: relative;
    padding-bottom: 100%;
    height: 0;

    .content, img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 100%;
    }
}

.center-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
