
$font1: 'raleway', sans-serif;
$font2: Arial, serif;

$weight-regular: 400;
$weight-bold: 700;
$weight-black: 900;

$c-black: #000;
$c-white: #FFF;

$c-red: #e33349;
$c-grey: #a7a7a7;
$c-grey-light: #9d9d9d;
$c-light-grey: #cdcdcd;
$c-grey-dark: #8a8989;

$c-green: #97C11F;

$c-dotted:#515151;

$c-footer: #3e3e3e;

$box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.1);

$break-duration: 1.2s;
$ease: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-power2:cubic-bezier(0.645, 0.045, 0.355, 1.000);

$perspective: 120rem;
