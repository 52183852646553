html{

    font-size: 60%;

    @include mobile-large-up{
        font-size: 75%;
    }

    @include tablet-narrow-up{
        font-size: 85%;
    }

    @include tablet-large-up{
        font-size: 55%;
    }

    @include desktop-narrow-up{
        font-size: 62.5%;
    }

    @include desktop-default-up{
        font-size: 70%;
    }

    @include desktop-large-up{
        font-size: 78%;
    }

    /*
    @include desktop-narrow-up{
        font-size: 72%;
    }

    @include desktop-default-up{
        font-size: 82%;
    }

    @include desktop-large-up{
        font-size: 90%;
    }

    @include desktop-ultralarge-up{
        font-size: 100%;
    }
    */

}

body{
    font-family: $font1;
    font-size: 1.6em;
    font-style: normal;
    font-weight: $weight-regular;
    line-height: 1.4;
    color: $c-white;
}


html, body {
    margin: 0;
    padding: 0;
    text-align: left;
	background-color: $c-black;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}


*{
	margin:0;
	padding:0;
	outline:none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

img{
	display:block;
	border:none;
    width: 100%;
    max-width:100%;
    height: auto;
}

main, header, nav, footer, svg { display: block; }

video{
    display: block;
}

h1,h2,h3,h4,p{
	font-weight:normal;
}

textarea{
    resize:none;
}

ol, ul, dl{
    list-style: inside;
    list-style-type: none;
}
