
/********************************************************
*   CONTENT
*******************************/

html{
    &.presentation{
        header{
            navigation{
                div.menu{
                    svg{
                        rect{
                            fill:$c-white;
                        }
                    }
                }
            }
        }
    }
    &.content-news{
        section.copy + section.copy{
            div.content-padding{
                padding-top: 0;
            }
        }
    }
}

header, footer{

    a.logo{
        pointer-events: all;
        svg{
            width: 10rem;
            height: 9.2rem;
        }
        @include tablet-large-down{
            svg{
                width: 10rem * 0.8;
                height: 9.2rem * 0.8;
            }
        }
    }

    navigation{

        div.menu{
            display: none;
            cursor: pointer;
            pointer-events: all;
            svg{
                width: 3.5rem;
                height: 3.5rem;
                rect{
                    fill:$c-black;
                }
            }
            @include tablet-large-down{
                display: block;
            }
        }

        div.close{
            display: none;
            cursor: pointer;
            pointer-events:all;
            svg{
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        a{

            text-align: center;

            &.text{
                &:after {
                    display: block;
                    content: attr(title);
                    font-weight: $weight-bold;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }

            &.act, &:hover{
                font-weight: $weight-bold;
            }


            &.facebook{
                margin-left:5.8rem;
                svg{
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            &.instagram{
                margin-left:1.5rem;
                svg{
                    width: 2.3rem;
                    height: 2.3rem;
                }
            }
            &.twitter{
                margin-left:1.5rem;
                svg{
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            &.youtube{
                margin-left:1.5rem;
                svg{
                    width: 2.8rem;
                    height: 2.3rem;
                }
            }

            &.text{
                margin-left:4rem;
            }
            &:first-child{
                margin-left:0;
            }

            @include tablet-large-down{

                &.facebook{
                    margin-left:5.8rem;
                    svg{
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
                &.instagram{
                    margin-left:1.5rem;
                    svg{
                        width: 2.3rem;
                        height: 2.3rem;
                    }
                }
                &.twitter{
                    margin-left:1.5rem;
                    svg{
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
                &.youtube{
                    margin-left:1.5rem;
                    svg{
                        width: 2.8rem;
                        height: 2.3rem;
                    }
                }

                &.text{
                    display: none;
                }

            }
        }
    }

}


main{

    div.button{

        cursor: pointer;

        &.stories{
            left:4rem;
        }
        &.life{
            right:4rem;
        }
        span{
            display: block;
            margin-top:1.5rem;
            text-align: center;
            //opacity: 0;
            //transition: opacity 0.4s $ease-power2;
        }
        svg{
            margin: 0 auto;
            width: 6rem;
            height: 6rem;
        }

        &:hover{
            span{
                //opacity: 1;
            }
        }

    }

}

section{

    &.overlay{

        background-color: rgba(0,0,0,0.8);
        transition: background 0.4s $ease-power2;
        padding:0;

        &.more-transparence{
            background-color: rgba(0,0,0,0.7);
        }

        div.content-wrapper{

            div.swiper-container {
                padding:10rem 0;

                div.swiper-wrapper{
                    transition-timing-function: $ease-power2;
                }

                .swiper-pagination-bullets{
                    .swiper-pagination-bullet{
                        width: 1.2rem;
                        height: 1.2rem;
                        background:$c-white;
                        border:none;
                        opacity:0.3;
                        transform: scale(0.95);
                        transition: all 0.3s $ease-power2;
                        &.swiper-pagination-bullet-active{
                            opacity:1;
                            transform: scale(1);
                        }
                    }
                }

                @include tablet-large-down{
                    padding:0;
                }

            }

        }

        div.grid-container{
            position: relative;
            margin-top: 9rem;
            margin-bottom: 10rem;

            div.graph{

                position: absolute;
                width: 100%;
                height: 100%;
                top: .2rem;
                display: none;

                svg{
                    width: 100%;
                    circle{
                        cursor: pointer;
                    }
                }

                div.tooltip-template{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    background-color: $c-white;
                    margin-bottom: 2rem;
                    padding:1.5rem;

                    &:before{
                        position: absolute;
                        left:-2.5rem;
                        top:1.5rem;
                        transform: translateX(-50%) translateY(-50%);
                        position: absolute;

                        content:"";

                        width: 10px;
                        height: 10px;
                        z-index: 2;

                        border-radius: 50%;
                        background-color: $c-white;
                    }
                    &:after{
                        position: absolute;
                        left:0;
                        top:1.5rem;
                        transform: translateX(-80%);
                        content: "";
                        width: 0;
                        height: 0;
                        border-top: 2rem solid $c-white;
                        border-left: 2rem solid transparent;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    div.text-content{
                        color: $c-black;
                        order: 2;
                        margin-top: 2rem;
                        @include tablet-large-down{
                            margin-top: 0;
                        }
                    }
                    h3{
                        margin-bottom: 1rem;
                    }
                    h4{
                        margin-bottom: .8rem;
                    }

                }

            }

            div.grid-label{
                position: absolute;
                &.label-years{
                    top:-2rem;
                    &.label-years-start{
                        left:0;
                    }
                    &.label-years-end{
                        right:0;
                    }
                }
                &.label-up{
                    top:2rem;
                    transform: rotate(-90deg);
                    &.label-up-start{
                        left:-4rem;
                    }
                    &.label-up-end{
                        right:-4rem;
                    }
                }
                &.label-down{
                    bottom:1.3rem;
                    transform-origin: left bottom;
                    transform: rotate(-90deg);
                    &.label-down-start{
                        left:-1.8rem;
                    }
                    &.label-down-end{
                        right:-8.2rem;
                    }
                }
            }

            @include tablet-large-down{

                margin-top:4rem;
                margin-bottom: 0rem;
                margin-left: 2.5rem;

                > img{
                    display: none;
                }
                div.grid-label{
                    display: none;
                }
                div.graph{
                    position: static;
                    top: 0;
                    &:after{
                        position: absolute;
                        left: -2.5rem;
                        top:0;
                        width:.1rem;
                        height: 100%;
                        content: "";
                        background-color: $c-white;
                    }
                    svg{
                        display: none;
                    }
                }
            }

        }

        div.button.close{
            position: absolute;
            z-index: 100;
            top:50%;
            left: 4rem;
            transform: translateY(-50%);
            cursor: pointer;
            opacity: 1;
            visibility: visible;
            display: block;
            &.close-right{
                left: auto;
                right: 4rem;
            }
        }

        div.swiper-button-prev, div.swiper-button-next{
            background:none;
            //width: 6rem;
            //height: 12rem;
            width: 3.5vw;
            height: calc(3.5vw * 2);
            margin-top:0;
            opacity: 1;
            transition: opacity 0.4s $ease-power2;
            &.swiper-button-disabled{
                opacity: 0;
            }
            img{
                width: 100%;
                height: auto;
            }
        }

        div.overlay-content{
            h2, span{
                line-height: 1.1;
            }
        }

    }

    &.ui{

        &.disabled{
            display: none;
        }

        div.controls {

            padding:0 4rem;

            .progress {

                cursor:pointer;
                flex:7 1 auto;

                height:1rem;
                background-color:rgba(255,255,255,0.3);
                border-radius:1px;

                div.progress-bar {
                    width:0%;
                    height:100%;
                    display:block;
                    background-color:rgba(255,255,255,0.6);
                    border-radius:1px;
                }

            }

            button {

                flex:0 0 auto;
                margin-right:2rem;
                border:none;
                cursor:pointer;
                background:transparent;

                &:hover, &:focus{
                    opacity:0.5;
                }

                &.playpause{
                    width: 1.8rem;
                    height: 1.8rem;
                }
                &.mute{
                    width: 2.4rem;
                    height: 2.4rem;
                }

                svg{
                    width: 100%;
                    height: 100%;
                    path, polygon, rect{
                        fill:$c-white;
                    }
                }

                &[data-state="play"] {
                    svg{
                        &:nth-child(2){
                            display:none;
                        }
                    }
                }
                &[data-state="pause"] {
                    svg{
                        &:nth-child(1){
                            display:none;
                        }
                    }
                }

                &[data-state="mute"] {
                    svg{
                        &:nth-child(2){
                            display:none;
                        }
                    }
                }
                &[data-state="unmute"] {
                    svg{
                        &:nth-child(1){
                            display:none;
                        }
                    }
                }
            }

        }

    }

    &.space{

        div.scene{

            &.scene-intro, &.scene-transition{

                background-color: $c-black;

                h1{
                    margin-bottom: 3.7rem;
                }
                p{
                    font-size: 2rem;
                }

                button{

                    border:1px solid $c-white;
                    background-color: transparent;
                    border-radius: 0;
                    box-shadow: none;
                    padding:1rem 4rem;
                    display: table;
                    vertical-align: middle;

                    pointer-events: all;
                    cursor: pointer;

                    font-size: 2rem;
                    color: $c-white;

                    margin:6rem auto;
                    margin-bottom: 2rem;

                    transition: all 0.4s ease-in-out;

                    &:hover{
                        background-color: $c-white;
                        color: $c-black;
                    }

                }
                svg{
                    display: inline-block;
                    margin:0 .5rem;
                    width: 2.4rem;
                    height: 5.5rem;
                    vertical-align: middle;
                    margin-bottom: .2rem;
                }
                span{
                    display: inline-block;
                    margin-top:1.5rem;
                }
            }

            &.scene-intro{
                span{
                    margin-left: 2.3rem;
                    margin-top:0;
                }
            }

            &.scene-transition{

                background-color: transparent;

                p{
                    display: none;
                }
                svg{
                    display: block;
                    margin:0 auto;
                    width: 2.4rem;
                    height: 5.5rem;
                    vertical-align: middle;
                    margin-bottom: 0;
                }
                span{
                    display: block;
                    margin-top:1.5rem;
                }
            }

            // &:nth-child(2){
            //     svg{
            //         > rect{
            //             mask: url(#mymask1);
            //         }
            //     }
            // }
            // &:nth-child(3){
            //     svg{
            //         > rect{
            //             mask: url(#mymask2);
            //         }
            //     }
            // }
            // &:nth-child(4){
            //     svg{
            //         > rect{
            //             mask: url(#mymask3);
            //         }
            //     }
            // }
            // &:nth-child(5){
            //     svg{
            //         > rect{
            //             mask: url(#mymask4);
            //         }
            //     }
            // }
            // &:nth-child(6){
            //     svg{
            //         > rect{
            //             mask: url(#mymask5);
            //         }
            //     }
            // }
            // &:nth-child(7){
            //     svg{
            //         > rect{
            //             mask: url(#mymask6);
            //         }
            //     }
            // }
            // &:nth-child(8){
            //     svg{
            //         > rect{
            //             mask: url(#mymask7);
            //         }
            //     }
            // }
            // &:nth-child(9){
            //     svg{
            //         > rect{
            //             mask: url(#mymask8);
            //         }
            //     }
            // }

            div.mask-container{

                svg {

                    > rect {
                        fill: rgb(0,0,0);
                    }

                    mask {
                        rect{
                            fill:white;
                        }
                        text{
                            font-family: $font1;
                            font-weight: $weight-black;
                            text-transform: uppercase;
                            text-rendering: geometricPrecision;
                        }
                    }

                }

            }

        }

    }

    &.copy{

        h1{
            margin-bottom:4rem;
        }
        h2{
            margin-bottom:3.2rem;
        }
        h3{
            margin-bottom:3.2rem;
        }
        h4{
            margin-bottom:1.5rem;
        }
        p + h3{
            margin-top:3.6rem;
        }
        p + p{
            margin-top: 3rem;
        }
        p + a{
            margin-top: 3rem;
        }

        .textlink{
            position: relative;
            display: inline-block;
            font-weight: $weight-black;
            div.link-arrow{
                svg{
                    path{
                        stroke:$c-black;
                    }
                }
            }
        }

        div.linklist{

            margin-top: 6rem;

            a{
                display: block;
                position: relative;
                line-height: 1.3;

                div.link-arrow{
                    position: absolute;
                    left: 0;
                    top: .2rem;
                }
                padding-left: 2rem;
            }

            a + a, span + a, a + span{
                margin-top: 1rem;
            }

            span{
                display: block;
                font-weight: 900;
                padding-left: 2rem;
                line-height: 1.3;
            }

            @include tablet-large-down{
                margin-top: 3rem;
                a + a{
                    margin-top: 1.5rem;
                }
            }

        }

        div.iconlist{

            margin-top: 6rem;

            > div{
                position: relative;
                padding-left: 2rem;
                line-height: 1.3;
                margin-top: 1rem;

                &:first-child{
                    margin-top: 0;
                }
            }

            div.link-arrow{
                position: absolute;
                left: 0;
                top: .2rem;
                svg{
                    path{
                        stroke:$c-black;
                        stroke-width:2;
                    }
                }
            }

            a{
                display: inline-block;
                line-height: 1.3;
                font-weight: 900;
            }



            span{
                display: block;
                font-weight: 900;
                padding-left: 2rem;
                line-height: 1.3;
            }

            @include tablet-large-down{
                margin-top: 3rem;
                > div{
                    font-size: 1.5rem;
                }
            }

        }

        .round{
            border-radius: 100%;
        }

        &.lead{
            p{
                font-size: 2rem;
            }
            div.column-content{
                &:nth-child(n+2){
                    display: none;
                }
            }
            @include tablet-large-down{

            }
        }

        div.content-padding{
            div.split-rows{
                &.split-rows-4{
                    &.projectlead{
                        margin-top: 7.5rem;

                        justify-content: center;
                        flex-wrap: wrap;

                        div.column{
                            div.square-maxwidth{
                                margin-bottom: 4rem;
                            }
                            div.text{
                                padding:0 2rem;
                            }
                            h3{
                                margin-bottom: 2.5rem;
                            }
                        }
                        @include tablet-large-down{
                            margin-top: 3rem;
                            div.column{
                                margin-bottom: 2rem;
                                div.square-maxwidth{
                                    margin-bottom: 0rem;
                                }
                                h3{
                                    margin-bottom: 1rem;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include tablet-large-down{
            h1{
                margin-bottom:2rem;
            }
            h2{
                margin-bottom:1.6rem;
            }
            p + p{
                margin-top: 1.4rem;
            }
            p + a{
                margin-top: 1.4rem;
            }
            &.lead{
                p{
                    font-size: 1.8rem;
                }
            }
        }

    }

    &.image{

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: $c-white;
        //min-height: 60rem; // 60rem;

        &.hero{
            height: 0;
            padding-bottom: 31% !important;
            > div{
                display: none;
            }
        }

        &.ummah{
            background-image:url(../images/background-ummah.jpg);
            background-position: center right;
        }

        .textlink{
            div.link-arrow{
                svg{
                    path{
                        stroke:$c-white;
                    }
                }
            }
        }

        @include tablet-large-down{
            min-height:30rem;
        }

    }

    &.border-top{
        div.content-padding{
            padding-top:0;
            div.border{
                width: 100%;
                height: .1rem;
                background-image: linear-gradient(to right, $c-dotted 33%, rgba(255,255,255,0) 0%);
                background-position: top;
                background-size: 5px 1px;
                background-repeat: repeat-x;
                margin-bottom:8rem;
                flex:none;
                @include tablet-large-down{
                    margin-bottom:3rem;
                }
            }
        }
    }

    &.slider + &.border-top, &.image + &.border-top{
        div.border{
            display: none;
        }
        div.content-padding{
            padding-top:8rem;
            @include tablet-large-down{
                padding-top:4rem;
            }
        }
    }

    &.news{

        div.content-padding{
            width: 80%;
            padding-top: 0;

        }

        > svg{
            position: relative;
            top: -4rem;
            margin:0 auto;
            width: 4rem;
            height: 4rem;
            //animation:spin 2s linear infinite;
            opacity: 0;
            visibility: hidden;
        }

        @keyframes spin { 100% { transform:rotate(360deg); } }

        div.news-title-mobile{
            display: none;
        }

        div.news-item{

            position: relative;
            padding:5rem 0;
            opacity: 1;
            transition:opacity 0.5s $ease-power2;

            &.new{
                opacity: 0;
            }

            div.news-container{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                > a{
                    display: block;
                    width: 24%;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                div.news-text{
                    padding-left: 2rem;
                    width: 76%;
                    p.mobile{
                        display: none;
                    }
                    svg{
                        path{
                            stroke:$c-black;
                        }
                    }
                }
            }

            h4{
                margin-bottom: 2.5rem;
            }
            h2,p{
                margin-bottom: 2.2rem;
            }

            div.border{
                position: absolute;
                top: 0;
                width: 100%;
                height: .1rem;
                background-image: -webkit-gradient(linear, left top, right top, color-stop(33%, #515151), color-stop(0%, rgba(255, 255, 255, 0)));
                background-image: linear-gradient(to right, #515151 33%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 5px 1px;
                background-repeat: repeat-x;
                &:last-child{
                    top:inherit;
                    bottom:0;
                }
            }

            &:last-child{
                //margin-bottom: 3rem;
            }

        }

        div.news-item + div.news-item{
            div.border{
                &:first-child{
                    display: none;
                }
            }
        }

        @include tablet-large-down{
            > svg{
                top:-1.5rem;
            }
            div.content-padding{
                width: 100%;
                padding-bottom:3rem;
            }
            div.news-title-mobile{
                display: block;
                margin-bottom:3rem;
            }
            div.news-item{
                padding:2.5rem 0;
                h4, h2{
                    margin-bottom: 0;
                }
                h4{
                    margin-bottom:.7rem;
                }
                div.news-container{
                    > a{
                        width: 35%;
                    }
                    div.news-text{
                        width: 65%;
                        p{
                            display: none;
                            &.mobile{
                                display: block;
                            }
                        }
                    }
                    h2,h4{
                        display: none;
                    }
                }
            }

        }
    }

    &.slider{
        position: relative;
        overflow: hidden;
        padding:0 !important;
        //min-height: 50rem;

        div.swiper-container{
            overflow:visible;
            padding:0 calc(4rem + 24%);
            background-color: $c-black;

            //height: 50rem;
            div.swiper-wrapper{
                //height: 50rem;
                div.swiper-slide{
                    //height: 50rem;
                    transition: opacity 0.4s $ease-power2;
                    opacity: 0.4;
                    &.swiper-slide-active{
                        opacity: 1;
                    }
                }
            }
            div.swiper-button-prev, div.swiper-button-next{
                background:none;
                width: 6rem;
                height: 12rem;
                margin-top: 0;
                transform: translateY(-50%);
            }
            div.swiper-button-prev{
                left: calc(4rem + 4%);
            }
            div.swiper-button-next{
                right: calc(4rem + 4%);
            }
            div.swiper-pagination-bullets{
                bottom: 2rem;
            }
            .swiper-pagination-bullet{
                transition: all 0.5s $ease-power2;
                background:$c-white;
                opacity: 0.4;
                width: 1rem;
                height: 1rem;
                &.swiper-pagination-bullet-active{
                    opacity: 1;
                }
            }

            @include tablet-large-down{
                padding:0 calc(2rem + 4%);
                div.swiper-pagination-bullets{
                    bottom: 1rem;
                }
                div.swiper-button-prev, div.swiper-button-next{
                    width: 2.5rem;
                    height: 5rem;
                }
                div.swiper-button-prev{
                    left: calc(2rem + 8%);
                }
                div.swiper-button-next{
                    right: calc(2rem + 8%);
                }
            }

        }

    }

}

div.mobile-navigation{

    user-select: none;
    background-color: $c-black;
    color: $c-white;
    svg{
        rect, path{
            fill:$c-white;
        }
    }
    .logo{
        position: absolute;
        top:2rem;
        left: 2rem;
        z-index: 10;
        svg{
            width: 8rem;
            height: 7.36rem;
        }
    }
    .close{
        position: absolute;
        top:2rem;
        right: 2rem;
        z-index: 10;
        cursor: pointer;
        user-select: none;
        svg{
            width: 2.5rem;
            height: 2.5rem;
            path{
                stroke:#fff;
                stroke-width:3;
                stroke-miterlimit:10;
            }
        }
    }
    navigation{
        a{
            font-size: 4rem;
            font-weight: $weight-black;
            display: block;
            margin:0.5rem 0;
            padding:1rem;
            padding-left: 0;
            &.act{
                color: $c-black;
                background-color: $c-white;
                padding-left: 1rem;
            }
        }
    }
    div.followus{
        text-align: center;
        margin-bottom: 2.2rem;
    }
    div.bottom{
        padding-bottom: 3rem;
    }
    div.social{

        svg{
            width: 2.5rem;
            height: 2.5rem;
        }
        a{
            &:nth-child(2){
                svg{
                    width: 2.3rem;
                    height: 2.3rem;
                }
            }
            &:nth-child(4){
                svg{
                    width: 2.8rem;
                    height: 2.3rem;
                }
            }
        }
    }
}

div.content-item{
    margin:0 auto;
    max-width:70rem;

    h3{
        margin-bottom: 1.5rem;
        font-size: 2rem;
    }

    div.play{

        position: relative;
        margin-bottom: 3.5rem;

        svg{
            position: absolute;
            top:45%;
            left:50%;
            width: 7rem;
            height: 7rem;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
        }
        &:before{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;

            background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
            background: radial-gradient(ellipse at center, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);

            opacity: 0.3;
            content:"";
            transition: all 0.4s $ease-power2;
        }

    }
    h2{
        margin-bottom: 2rem;
    }
    div.mobile{
        display: none;
    }

    @include tablet-large-down{
        position: relative;
        max-width:none;

        div.play{
            margin-bottom: 0;
            &:after{
                top: 42%;
            }
            &:before{
                opacity: 0.5;
                background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 130%);
                background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 130%);
                background: radial-gradient(ellipse at center, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 130%);
            }
            &:hover{
                &:before{
                    opacity: 0.5;
                }
            }
        }

        > h3, > h2, > p{
            display: none;
        }

        div.mobile{
            position: absolute;
            display: block;

            z-index: 100;
            bottom: calc(2rem + 4vw);
            left: calc(2rem + 4vw);

            h3{
                margin-bottom: 0.7rem;
                font-size: 2rem;
            }
            h2{
                margin-bottom: 0.5rem;
                font-size: 4rem;
            }

            div.textlink{
                font-weight: $weight-black;
                font-size: 1.8rem;
            }
        }

    }

    @include tablet-narrow-down{
        div.mobile{
            bottom: calc(2rem + 3vw);
            left: calc(2rem + 3vw);
        }
    }

    @include mobile-xtra-large-down{
        div.mobile{
            bottom: calc(2rem + 0.5vw);
            left: calc(2rem + 0.5vw);

            h3{
                margin-bottom: 0.7rem;
                font-size: 1.8rem;
            }
            h2{
                margin-bottom: 0.2rem;
                font-size: 3rem;
            }
            div.textlink{
                font-size: 1.8rem;
            }

        }
    }

    @include mobile-large-down{
        div.mobile{
            bottom: calc(2rem);
            left: calc(2rem);

            h3{
                margin-bottom: 0.4rem;
                font-size: 1.5rem;
            }
            h2{
                margin-bottom: 0.1rem;
                font-size: 2rem;
            }
            div.textlink{
                font-size: 1.4rem;
            }

        }
    }

}

div.button{
    position: relative;
    display: inline-block;
    //font-weight: $weight-black;

    div.link-arrow{
        position: relative;
        top: .1rem;
        display: inline-block;
        margin-right: .4rem;
        svg{
            width: 1.2rem;
            height: 1.2rem;
            path{
                stroke:$c-white;
            }
        }
    }

}
