@charset "UTF-8";

@import 'settings';
@import 'media-queries';

/* DEFAULTS */
@import 'reset';
@import 'font-face';
@import 'base';
@import 'helpers';

/* DEPS */
@import "~swiper/dist/css/swiper.css";

/* PROJECT */
@import 'fonts';
@import 'labels';
@import 'sms-theme';
@import 'structure';
@import 'content';
@import 'colors';

@import 'loader';
