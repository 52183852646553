/* -414 */
/* -768 */
/* -768 */
/* -1024 */
/* -1280 */
/* -1920 */
/* -2,5k */
/* -4k */
/* 414+ */
/* 768+ */
/* 1024+ */
/* 1280+ */
/* 1920+ */
/* 2,5k+ */
/* 4k+ */
/* DEFAULTS */
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
@import url(~swiper/dist/css/swiper.css);
html {
  color: #000;
  background: #FFF; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%; }

legend {
  color: #000; }

/*
@font-face {
    font-family: 'bikoblack';
    src: url('../fonts/biko_black-webfont.woff2') format('woff2'),
         url('../fonts/biko_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
*/
html {
  font-size: 60%;
  /*
    @include desktop-narrow-up{
        font-size: 72%;
    }

    @include desktop-default-up{
        font-size: 82%;
    }

    @include desktop-large-up{
        font-size: 90%;
    }

    @include desktop-ultralarge-up{
        font-size: 100%;
    }
    */ }
  @media screen and (min-width: 414px) {
    html {
      font-size: 75%; } }
  @media screen and (min-width: 769px) {
    html {
      font-size: 85%; } }
  @media screen and (min-width: 1024px) {
    html {
      font-size: 55%; } }
  @media screen and (min-width: 1281px) {
    html {
      font-size: 62.5%; } }
  @media screen and (min-width: 1921px) {
    html {
      font-size: 70%; } }
  @media screen and (min-width: 2543px) {
    html {
      font-size: 78%; } }

body {
  font-family: "raleway", sans-serif;
  font-size: 1.6em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  color: #FFF; }

html, body {
  margin: 0;
  padding: 0;
  text-align: left;
  background-color: #000;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent; }

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

img {
  display: block;
  border: none;
  width: 100%;
  max-width: 100%;
  height: auto; }

main, header, nav, footer, svg {
  display: block; }

video {
  display: block; }

h1, h2, h3, h4, p {
  font-weight: normal; }

textarea {
  resize: none; }

ol, ul, dl {
  list-style: inside;
  list-style-type: none; }

/********************************************************
*   HELPERS
*******************************/
span.clear {
  display: block;
  clear: both;
  width: 1px;
  height: 0.001%;
  font-size: 0px;
  line-height: 0px;
  float: none !important; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.full-centered {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%); }

div.table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed; }
  div.table div.cell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    /* text-align:center; */
    position: relative; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0; }
  .videoWrapper iframe, .videoWrapper video, .videoWrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.is-hidden-desktop {
  display: block; }

.is-hidden-mobile {
  display: none; }

.no-margin {
  margin: 0 !important; }

.square {
  position: relative;
  padding-bottom: 100%;
  height: 0; }
  .square .content, .square img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 100%; }

.center-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

/* DEPS */
/* PROJECT */
/********************************************************
*   FONTS
*******************************/
h1 {
  font-family: "raleway", sans-serif;
  font-size: 8rem;
  font-weight: 900;
  line-height: 1.05;
  display: block; }

h2 {
  font-family: "raleway", sans-serif;
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.05;
  display: block; }

h3 {
  font-family: "raleway", sans-serif;
  font-size: 2.4rem;
  font-weight: 900;
  line-height: 1.05;
  display: inline-block; }

h4 {
  font-family: "raleway", sans-serif;
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 1.3;
  display: block; }

h1, h2, h3, h4 {
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  h1 span, h2 span, h3 span, h4 span {
    font-size: inherit; }

a {
  font-family: "raleway", sans-serif;
  line-height: 1;
  text-decoration: none;
  color: inherit;
  transition: color 200ms ease-out; }
  a.act, a:hover {
    text-decoration: none; }
  a.textlink {
    position: relative;
    display: inline-block;
    font-weight: 900; }
    a.textlink div.link-arrow {
      position: relative;
      top: .1rem;
      display: inline-block;
      margin-right: .5rem; }

p {
  font-family: "raleway", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5; }
  p.lead {
    font-size: 2rem; }
  p.bold {
    font-family: "raleway", sans-serif; }
  p strong {
    font-weight: 700; }
  p em {
    font-style: italic; }
  p.title {
    text-decoration: underline; }
  p a {
    transition: border 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-bottom: 1px solid #8a8989; }
    p a:hover {
      border-bottom: 1px solid #a7a7a7; }
  p span.italic {
    font-style: italic; }
  p span.bold {
    font-family: "raleway", sans-serif; }
  p span.smaller {
    margin-top: 1rem;
    font-size: 1.1rem;
    display: block; }
  p:last-child {
    margin-bottom: 0; }

span {
  font-family: "raleway", sans-serif;
  font-size: 1.6rem;
  line-height: 1.4; }

ul li {
  font-family: "raleway", sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

@media screen and (max-width: 1023px) {
  h1 {
    font-size: 4rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 2rem; }
  h4 {
    font-size: 1.5rem; }
  a {
    font-size: 1.5rem; }
  p {
    font-size: 1.5rem; }
    p.lead {
      font-size: 1.8rem; } }

/********************************************************
*   STYLED CHECK & RADIO
*******************************/
label.styled {
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 2rem; }
  label.styled:last-child {
    margin-bottom: 0; }
  label.styled.error, label.styled.has-danger, label.styled.error a, label.styled.error span {
    color: #f00; }
  label.styled span {
    display: inline-block;
    padding-left: 0px;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.7; }
    label.styled span a {
      text-decoration: underline; }
  label.styled img, label.styled i {
    position: absolute;
    top: 0rem;
    left: 0rem;
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.77, 0, 0.175, 1);
    pointer-events: none;
    width: 2rem;
    height: 2rem; }
    label.styled img.on, label.styled i.on {
      opacity: 0; }
    label.styled img.off, label.styled i.off {
      opacity: 1; }
  label.styled.checked img.off, label.styled.checked i.off {
    opacity: 0; }
  label.styled.checked img.on, label.styled.checked i.on {
    opacity: 1; }
  label.styled input {
    position: absolute;
    left: -9999px; }

.tippy-tooltip.sms-theme {
  background-color: #FFF;
  border: none;
  border-radius: 0;
  max-width: 60rem;
  padding: 3rem;
  box-shadow: 0.5rem 0.5rem 3rem 0.5rem rgba(0, 0, 0, 0.3); }
  .tippy-tooltip.sms-theme div.tippy-content div.tooltip-template {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: start;
        align-items: flex-start; }
    .tippy-tooltip.sms-theme div.tippy-content div.tooltip-template div.text-content {
      width: 60%;
      color: #000;
      text-align: left;
      padding-right: 1.5rem; }
      .tippy-tooltip.sms-theme div.tippy-content div.tooltip-template div.text-content.only {
        padding: 0;
        width: 100%;
        max-width: 45rem; }
    .tippy-tooltip.sms-theme div.tippy-content div.tooltip-template img {
      width: 40%;
      max-width: 25rem; }
    .tippy-tooltip.sms-theme div.tippy-content div.tooltip-template h3 {
      font-size: 2.8rem;
      margin: 1.2rem 0;
      margin-bottom: 1.5rem; }
    .tippy-tooltip.sms-theme div.tippy-content div.tooltip-template p {
      font-size: 1.6rem; }
  .tippy-tooltip.sms-theme div.tippy-arrow {
    -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    bottom: -3rem; }

.tippy-popper[x-placement^=top] .tippy-tooltip.sms-theme .tippy-arrow {
  border-top: 3rem solid #FFF;
  border-right: 3rem solid transparent;
  border-left: 3rem solid transparent; }

.tippy-popper[x-placement^=bottom] .tippy-tooltip.sms-theme .tippy-arrow {
  border-top: 3rem solid #FFF;
  border-right: 3rem solid transparent;
  border-left: 3rem solid transparent;
  border-bottom: none;
  bottom: inherit;
  top: -3rem;
  -ms-transform: rotate(-90deg) translateY(100%) !important;
      transform: rotate(-90deg) translateY(100%) !important; }

/********************************************************
*   STRUCTURE
*******************************/
html {
  width: 100%; }
  html body {
    width: 100%; }
    html body.toggle-navigation div.mobile-navigation {
      display: block; }
    html body.toggle-navigation main {
      display: none; }
    html body.toggle-videoplayer header div.menu {
      display: none; }
    html body.toggle-videoplayer header div.close {
      display: block; }
    html body.toggle-videoplayer section.overlay {
      display: none !important; }
    html body.toggle-videoplayer section.video-player {
      display: block !important; }
    html body.toggle-videoplayer section.ui {
      display: -ms-flexbox !important;
      display: flex !important;
      height: 100vh;
      position: absolute;
      pointer-events: none;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-direction: column;
          flex-direction: column; }
      html body.toggle-videoplayer section.ui div.controls {
        opacity: 1; }
    html body.toggle-life header div.menu {
      display: none; }
    html body.toggle-life header div.close {
      display: block; }
    html body.toggle-life section.overlay div.content-wrapper {
      position: relative;
      overflow: hidden; }
    html body.toggle-life section.overlay div.overlay-stories {
      position: absolute;
      display: block;
      z-index: 0;
      filter: blur(3rem); }
    html body.toggle-life section.overlay div.overlay-life {
      display: block; }
  html.presentation {
    height: 100%;
    background-color: #000;
    overflow: hidden; }
    html.presentation body {
      height: 100%;
      overflow: hidden;
      background-color: #000; }
      html.presentation body header {
        position: absolute;
        width: calc(100% - 8rem);
        top: 0;
        left: 0;
        z-index: 5;
        padding-bottom: 0; }
      html.presentation body main {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 0;
        overflow: hidden; }
        html.presentation body main section {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          padding: 0; }
          html.presentation body main section.overlay {
            perspective: 120rem;
            z-index: 15;
            visibility: hidden;
            opacity: 0;
            pointer-events: none; }
          html.presentation body main section.ui {
            z-index: 10; }
          html.presentation body main section.space {
            perspective: 120rem;
            z-index: 5; }
          html.presentation body main section.video-player {
            perspective: 120rem;
            z-index: 0; }
          html.presentation body main section.enable-interaction {
            pointer-events: all; }
    @media screen and (max-width: 1023px) {
      html.presentation {
        height: auto;
        overflow: auto; }
        html.presentation body {
          height: auto;
          overflow: auto; }
          html.presentation body header {
            width: calc(100% - 4rem);
            z-index: 50; }
          html.presentation body main {
            position: static;
            height: auto; }
            html.presentation body main section {
              position: static; }
              html.presentation body main section.overlay {
                visibility: visible !important;
                opacity: 1 !important;
                pointer-events: all; }
                html.presentation body main section.overlay div.content-wrapper {
                  max-width: none;
                  -ms-transform: none !important;
                      transform: none !important; }
                html.presentation body main section.overlay div.button.close {
                  display: none; }
              html.presentation body main section.video-player {
                height: 100vh; }
              html.presentation body main section.ui, html.presentation body main section.space, html.presentation body main section.video-player {
                display: none; } }
  html.content body main section {
    padding: 0 4rem; }
    @media screen and (max-width: 1023px) {
      html.content body main section {
        padding: 0 2rem; } }

header {
  padding: 4rem 0rem;
  margin: 0 4rem;
  padding-bottom: 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: start;
      align-items: flex-start; }
  header navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  @media screen and (max-width: 1023px) {
    header {
      padding: 2rem 0rem;
      margin: 0 2rem;
      pointer-events: none; }
      header navigation {
        display: block;
        visibility: visible !important;
        opacity: 1 !important;
        -ms-transform: none !important;
            transform: none !important; }
        header navigation a {
          display: none; } }

section div.content-padding {
  padding: 8rem 8%; }
  @media screen and (max-width: 1023px) {
    section div.content-padding {
      padding: 3rem 4%; } }

section.overlay div.content-wrapper {
  width: 100%;
  max-width: 76%; }
  section.overlay div.content-wrapper div.swiper-container {
    width: 100%;
    overflow: visible; }
    section.overlay div.content-wrapper div.swiper-container div.swiper-notification {
      display: none; }
    section.overlay div.content-wrapper div.swiper-container div.swiper-slide {
      padding: 0 1.7%;
      opacity: 0.4;
      transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
      section.overlay div.content-wrapper div.swiper-container div.swiper-slide.swiper-slide-active {
        cursor: pointer; }
      section.overlay div.content-wrapper div.swiper-container div.swiper-slide.swiper-slide-active {
        opacity: 1; }
    section.overlay div.content-wrapper div.swiper-container .swiper-pagination-bullets {
      bottom: 0; }

section.overlay div.overlay-content {
  position: relative;
  display: none;
  width: 100%; }
  section.overlay div.overlay-content.overlay-stories {
    display: block; }
  section.overlay div.overlay-content.overlay-life {
    margin-top: -3rem; }
    section.overlay div.overlay-content.overlay-life div.graph > div.tooltip-template {
      display: none; }

section.overlay div.button.close svg {
  width: 6rem;
  height: 6rem; }

section.overlay div.swiper-button-prev {
  left: auto;
  right: 100%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%); }

section.overlay div.swiper-button-next {
  right: auto;
  left: 100%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%); }

@media screen and (max-width: 1023px) {
  section.overlay div.content-wrapper div.swiper-container {
    overflow: auto; }
    section.overlay div.content-wrapper div.swiper-container div.swiper-wrapper {
      display: block;
      height: auto;
      -ms-transform: none !important;
          transform: none !important; }
      section.overlay div.content-wrapper div.swiper-container div.swiper-wrapper div.swiper-slide {
        padding: 0;
        width: 100% !important;
        opacity: 1; }
    section.overlay div.content-wrapper div.swiper-container div.fade, section.overlay div.content-wrapper div.swiper-container div.swiper-pagination {
      display: none; }
  section.overlay div.content-wrapper div.swiper-button-prev, section.overlay div.content-wrapper div.swiper-button-next {
    display: none; }
  section.overlay div.content-wrapper div.overlay-content.overlay-life {
    margin-top: 0;
    padding: 4rem 2rem;
    padding-top: 12rem;
    background-color: rgba(0, 0, 0, 0.4); }
    section.overlay div.content-wrapper div.overlay-content.overlay-life div.graph > div.tooltip-template {
      display: -ms-flexbox;
      display: flex; }
    section.overlay div.content-wrapper div.overlay-content.overlay-life h2 {
      font-size: 4rem;
      line-height: 1; }
  section.overlay div.content-wrapper div.close {
    display: block; } }

section.ui div.button {
  position: absolute;
  z-index: 1000;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  opacity: 0;
  visibility: hidden; }

section.ui div.controls {
  width: 100%;
  position: absolute;
  bottom: 4rem;
  z-index: 1000;
  opacity: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center; }

section.ui div.buffering {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  z-index: 500; }

section.ui div.mobileplaceholder {
  display: none; }

@media screen and (max-width: 1023px) {
  section.ui div.button {
    visibility: hidden !important;
    opacity: 0 !important; }
  section.ui div.controls {
    position: static;
    bottom: -2rem;
    padding: 0 2rem !important;
    visibility: visible !important;
    opacity: 1 !important;
    pointer-events: all; }
  section.ui div.buffering {
    bottom: 3rem; }
  section.ui div.mobileplaceholder {
    display: block;
    width: 100%;
    height: 56.25vw;
    margin-top: -1.2rem;
    margin-bottom: -4rem; } }

section.space div.scene {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  pointer-events: none;
  z-index: 5;
  visibility: hidden;
  opacity: 0; }
  section.space div.scene.scene-intro {
    visibility: visible;
    opacity: 1;
    z-index: 10;
    perspective: 120rem; }
    section.space div.scene.scene-intro div.content-wrapper {
      margin-top: 5vh; }
  section.space div.scene.scene-transition div.content-wrapper {
    margin-top: 70vh; }
  section.space div.scene div.content-wrapper {
    max-width: 64rem;
    text-align: center; }
  section.space div.scene div.image-container {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  section.space div.scene div.mask-container {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    position: relative; }
    section.space div.scene div.mask-container img {
      width: 50%;
      height: auto; }
    section.space div.scene div.mask-container svg {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      margin: 0 auto;
      width: 100%;
      height: auto; }

section.video-player {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  section.video-player video {
    width: 100%;
    height: 100%; }
  section.video-player div.dimm {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 50;
    background-color: #000; }
  section.video-player div.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: black; }
    section.video-player div.mask.top {
      top: -100%; }
    section.video-player div.mask.left {
      left: -100%; }
  section.video-player div.vertex {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    visibility: hidden;
    pointer-events: none;
    background-color: green;
    z-index: 10000; }
  @media screen and (max-width: 1023px) {
    section.video-player {
      z-index: 1000; }
      section.video-player video {
        width: 100% !important;
        height: auto;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%) !important;
            transform: translateY(-50%) !important; }
      section.video-player div.dimm, section.video-player div.mask, section.video-player div.vertex {
        display: none; } }

section.copy div.content-padding {
  margin: 0 auto; }
  section.copy div.content-padding.split-rows, section.copy div.content-padding div.split-rows {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    section.copy div.content-padding.split-rows div.column, section.copy div.content-padding div.split-rows div.column {
      margin-top: 6rem; }
      section.copy div.content-padding.split-rows div.column:nth-child(-n+4), section.copy div.content-padding div.split-rows div.column:nth-child(-n+4) {
        margin-top: 0; }
    section.copy div.content-padding.split-rows div.column-content, section.copy div.content-padding div.split-rows div.column-content {
      width: 48%; }
    section.copy div.content-padding.split-rows div.column-space, section.copy div.content-padding div.split-rows div.column-space {
      width: 10%; }
    section.copy div.content-padding.split-rows.split-rows-4 div.column, section.copy div.content-padding div.split-rows.split-rows-4 div.column {
      width: 23%;
      -ms-flex: none;
          flex: none; }
      section.copy div.content-padding.split-rows.split-rows-4 div.column div.square-maxwidth, section.copy div.content-padding div.split-rows.split-rows-4 div.column div.square-maxwidth {
        width: 65%;
        margin: 0 auto; }
  @media screen and (max-width: 1023px) {
    section.copy div.content-padding.split-rows, section.copy div.content-padding div.split-rows {
      display: block; }
      section.copy div.content-padding.split-rows div.column-content, section.copy div.content-padding div.split-rows div.column-content {
        width: 100%; }
      section.copy div.content-padding.split-rows div.column-content + div.column-content, section.copy div.content-padding.split-rows div.column-space + div.column-content, section.copy div.content-padding div.split-rows div.column-content + div.column-content, section.copy div.content-padding div.split-rows div.column-space + div.column-content {
        margin-top: 3rem; }
      section.copy div.content-padding.split-rows div.column-space, section.copy div.content-padding div.split-rows div.column-space {
        width: 0; }
      section.copy div.content-padding.split-rows.split-rows-4 div.column, section.copy div.content-padding div.split-rows.split-rows-4 div.column {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
            align-items: flex-start;
        -ms-flex-line-pack: stretch;
            align-content: stretch; }
        section.copy div.content-padding.split-rows.split-rows-4 div.column div.square-maxwidth, section.copy div.content-padding div.split-rows.split-rows-4 div.column div.square-maxwidth {
          width: 34%;
          max-width: 18rem; }
        section.copy div.content-padding.split-rows.split-rows-4 div.column div:nth-child(2), section.copy div.content-padding div.split-rows.split-rows-4 div.column div:nth-child(2) {
          width: 66%;
          padding-left: 2rem; } }

section.copy.centered {
  text-align: center; }
  section.copy.centered div.content-padding {
    text-align: center; }
  section.copy.centered div.centered {
    max-width: 84rem;
    margin: 0 auto; }
  section.copy.centered div.split-rows {
    max-width: 120rem;
    margin: 0 auto; }
  @media screen and (max-width: 1023px) {
    section.copy.centered {
      text-align: left; }
      section.copy.centered div.content-padding {
        text-align: left; }
      section.copy.centered div.centered {
        max-width: 100%; }
      section.copy.centered div.split-rows {
        max-width: 100%; } }

section.image div.content-padding {
  padding-top: 12rem;
  padding-bottom: 18rem; }
  @media screen and (max-width: 1023px) {
    section.image div.content-padding {
      padding-top: 3rem;
      padding-bottom: 6rem; } }

footer {
  padding: 4rem 0;
  margin: 0 4rem;
  padding-bottom: 9rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: start;
      align-items: flex-start; }
  footer div.followus {
    visibility: hidden;
    text-align: center; }
  footer navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    footer navigation div.menu {
      display: none !important; }
  @media screen and (max-width: 1023px) {
    footer {
      display: block;
      padding: 2rem 0;
      padding-bottom: 3rem; }
      footer div.followus {
        visibility: visible; }
      footer navigation {
        max-width: 40rem;
        margin: 0 auto;
        margin-top: 1.5rem;
        -ms-flex-pack: distribute;
            justify-content: space-around; }
        footer navigation a {
          margin-left: 0 !important; } }

div.mobile-navigation {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  min-height: 50rem;
  z-index: 10000; }
  div.mobile-navigation navigation {
    padding: 10rem calc(2rem + 4%);
    position: absolute;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-direction: column;
        flex-direction: column; }
    div.mobile-navigation navigation a {
      color: #FFF; }
  div.mobile-navigation div.bottom {
    position: absolute;
    bottom: 0;
    width: 100%; }
  div.mobile-navigation div.social {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    max-width: 30rem;
    margin: 0 auto; }

/********************************************************
*   CONTENT
*******************************/
html.presentation header navigation div.menu svg rect {
  fill: #FFF; }

html.content-news section.copy + section.copy div.content-padding {
  padding-top: 0; }

header a.logo, footer a.logo {
  pointer-events: all; }
  header a.logo svg, footer a.logo svg {
    width: 10rem;
    height: 9.2rem; }
  @media screen and (max-width: 1023px) {
    header a.logo svg, footer a.logo svg {
      width: 8rem;
      height: 7.36rem; } }

header navigation div.menu, footer navigation div.menu {
  display: none;
  cursor: pointer;
  pointer-events: all; }
  header navigation div.menu svg, footer navigation div.menu svg {
    width: 3.5rem;
    height: 3.5rem; }
    header navigation div.menu svg rect, footer navigation div.menu svg rect {
      fill: #000; }
  @media screen and (max-width: 1023px) {
    header navigation div.menu, footer navigation div.menu {
      display: block; } }

header navigation div.close, footer navigation div.close {
  display: none;
  cursor: pointer;
  pointer-events: all; }
  header navigation div.close svg, footer navigation div.close svg {
    width: 2.5rem;
    height: 2.5rem; }

header navigation a, footer navigation a {
  text-align: center; }
  header navigation a.text:after, footer navigation a.text:after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden; }
  header navigation a.act, header navigation a:hover, footer navigation a.act, footer navigation a:hover {
    font-weight: 700; }
  header navigation a.facebook, footer navigation a.facebook {
    margin-left: 5.8rem; }
    header navigation a.facebook svg, footer navigation a.facebook svg {
      width: 2.5rem;
      height: 2.5rem; }
  header navigation a.instagram, footer navigation a.instagram {
    margin-left: 1.5rem; }
    header navigation a.instagram svg, footer navigation a.instagram svg {
      width: 2.3rem;
      height: 2.3rem; }
  header navigation a.twitter, footer navigation a.twitter {
    margin-left: 1.5rem; }
    header navigation a.twitter svg, footer navigation a.twitter svg {
      width: 2.5rem;
      height: 2.5rem; }
  header navigation a.youtube, footer navigation a.youtube {
    margin-left: 1.5rem; }
    header navigation a.youtube svg, footer navigation a.youtube svg {
      width: 2.8rem;
      height: 2.3rem; }
  header navigation a.text, footer navigation a.text {
    margin-left: 4rem; }
  header navigation a:first-child, footer navigation a:first-child {
    margin-left: 0; }
  @media screen and (max-width: 1023px) {
    header navigation a.facebook, footer navigation a.facebook {
      margin-left: 5.8rem; }
      header navigation a.facebook svg, footer navigation a.facebook svg {
        width: 2.5rem;
        height: 2.5rem; }
    header navigation a.instagram, footer navigation a.instagram {
      margin-left: 1.5rem; }
      header navigation a.instagram svg, footer navigation a.instagram svg {
        width: 2.3rem;
        height: 2.3rem; }
    header navigation a.twitter, footer navigation a.twitter {
      margin-left: 1.5rem; }
      header navigation a.twitter svg, footer navigation a.twitter svg {
        width: 2.5rem;
        height: 2.5rem; }
    header navigation a.youtube, footer navigation a.youtube {
      margin-left: 1.5rem; }
      header navigation a.youtube svg, footer navigation a.youtube svg {
        width: 2.8rem;
        height: 2.3rem; }
    header navigation a.text, footer navigation a.text {
      display: none; } }

main div.button {
  cursor: pointer; }
  main div.button.stories {
    left: 4rem; }
  main div.button.life {
    right: 4rem; }
  main div.button span {
    display: block;
    margin-top: 1.5rem;
    text-align: center; }
  main div.button svg {
    margin: 0 auto;
    width: 6rem;
    height: 6rem; }

section.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  transition: background 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0; }
  section.overlay.more-transparence {
    background-color: rgba(0, 0, 0, 0.7); }
  section.overlay div.content-wrapper div.swiper-container {
    padding: 10rem 0; }
    section.overlay div.content-wrapper div.swiper-container div.swiper-wrapper {
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    section.overlay div.content-wrapper div.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 1.2rem;
      height: 1.2rem;
      background: #FFF;
      border: none;
      opacity: 0.3;
      -ms-transform: scale(0.95);
          transform: scale(0.95);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      section.overlay div.content-wrapper div.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
        opacity: 1;
        -ms-transform: scale(1);
            transform: scale(1); }
    @media screen and (max-width: 1023px) {
      section.overlay div.content-wrapper div.swiper-container {
        padding: 0; } }
  section.overlay div.grid-container {
    position: relative;
    margin-top: 9rem;
    margin-bottom: 10rem; }
    section.overlay div.grid-container div.graph {
      position: absolute;
      width: 100%;
      height: 100%;
      top: .2rem;
      display: none; }
      section.overlay div.grid-container div.graph svg {
        width: 100%; }
        section.overlay div.grid-container div.graph svg circle {
          cursor: pointer; }
      section.overlay div.grid-container div.graph div.tooltip-template {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        background-color: #FFF;
        margin-bottom: 2rem;
        padding: 1.5rem; }
        section.overlay div.grid-container div.graph div.tooltip-template:before {
          position: absolute;
          left: -2.5rem;
          top: 1.5rem;
          -ms-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          z-index: 2;
          border-radius: 50%;
          background-color: #FFF; }
        section.overlay div.grid-container div.graph div.tooltip-template:after {
          position: absolute;
          left: 0;
          top: 1.5rem;
          -ms-transform: translateX(-80%);
              transform: translateX(-80%);
          content: "";
          width: 0;
          height: 0;
          border-top: 2rem solid #FFF;
          border-left: 2rem solid transparent; }
        section.overlay div.grid-container div.graph div.tooltip-template:last-child {
          margin-bottom: 0; }
        section.overlay div.grid-container div.graph div.tooltip-template div.text-content {
          color: #000;
          -ms-flex-order: 2;
              order: 2;
          margin-top: 2rem; }
          @media screen and (max-width: 1023px) {
            section.overlay div.grid-container div.graph div.tooltip-template div.text-content {
              margin-top: 0; } }
        section.overlay div.grid-container div.graph div.tooltip-template h3 {
          margin-bottom: 1rem; }
        section.overlay div.grid-container div.graph div.tooltip-template h4 {
          margin-bottom: .8rem; }
    section.overlay div.grid-container div.grid-label {
      position: absolute; }
      section.overlay div.grid-container div.grid-label.label-years {
        top: -2rem; }
        section.overlay div.grid-container div.grid-label.label-years.label-years-start {
          left: 0; }
        section.overlay div.grid-container div.grid-label.label-years.label-years-end {
          right: 0; }
      section.overlay div.grid-container div.grid-label.label-up {
        top: 2rem;
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg); }
        section.overlay div.grid-container div.grid-label.label-up.label-up-start {
          left: -4rem; }
        section.overlay div.grid-container div.grid-label.label-up.label-up-end {
          right: -4rem; }
      section.overlay div.grid-container div.grid-label.label-down {
        bottom: 1.3rem;
        -ms-transform-origin: left bottom;
            transform-origin: left bottom;
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg); }
        section.overlay div.grid-container div.grid-label.label-down.label-down-start {
          left: -1.8rem; }
        section.overlay div.grid-container div.grid-label.label-down.label-down-end {
          right: -8.2rem; }
    @media screen and (max-width: 1023px) {
      section.overlay div.grid-container {
        margin-top: 4rem;
        margin-bottom: 0rem;
        margin-left: 2.5rem; }
        section.overlay div.grid-container > img {
          display: none; }
        section.overlay div.grid-container div.grid-label {
          display: none; }
        section.overlay div.grid-container div.graph {
          position: static;
          top: 0; }
          section.overlay div.grid-container div.graph:after {
            position: absolute;
            left: -2.5rem;
            top: 0;
            width: .1rem;
            height: 100%;
            content: "";
            background-color: #FFF; }
          section.overlay div.grid-container div.graph svg {
            display: none; } }
  section.overlay div.button.close {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 4rem;
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    display: block; }
    section.overlay div.button.close.close-right {
      left: auto;
      right: 4rem; }
  section.overlay div.swiper-button-prev, section.overlay div.swiper-button-next {
    background: none;
    width: 3.5vw;
    height: calc(3.5vw * 2);
    margin-top: 0;
    opacity: 1;
    transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
    section.overlay div.swiper-button-prev.swiper-button-disabled, section.overlay div.swiper-button-next.swiper-button-disabled {
      opacity: 0; }
    section.overlay div.swiper-button-prev img, section.overlay div.swiper-button-next img {
      width: 100%;
      height: auto; }
  section.overlay div.overlay-content h2, section.overlay div.overlay-content span {
    line-height: 1.1; }

section.ui.disabled {
  display: none; }

section.ui div.controls {
  padding: 0 4rem; }
  section.ui div.controls .progress {
    cursor: pointer;
    -ms-flex: 7 1 auto;
        flex: 7 1 auto;
    height: 1rem;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1px; }
    section.ui div.controls .progress div.progress-bar {
      width: 0%;
      height: 100%;
      display: block;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 1px; }
  section.ui div.controls button {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    margin-right: 2rem;
    border: none;
    cursor: pointer;
    background: transparent; }
    section.ui div.controls button:hover, section.ui div.controls button:focus {
      opacity: 0.5; }
    section.ui div.controls button.playpause {
      width: 1.8rem;
      height: 1.8rem; }
    section.ui div.controls button.mute {
      width: 2.4rem;
      height: 2.4rem; }
    section.ui div.controls button svg {
      width: 100%;
      height: 100%; }
      section.ui div.controls button svg path, section.ui div.controls button svg polygon, section.ui div.controls button svg rect {
        fill: #FFF; }
    section.ui div.controls button[data-state="play"] svg:nth-child(2) {
      display: none; }
    section.ui div.controls button[data-state="pause"] svg:nth-child(1) {
      display: none; }
    section.ui div.controls button[data-state="mute"] svg:nth-child(2) {
      display: none; }
    section.ui div.controls button[data-state="unmute"] svg:nth-child(1) {
      display: none; }

section.space div.scene.scene-intro, section.space div.scene.scene-transition {
  background-color: #000; }
  section.space div.scene.scene-intro h1, section.space div.scene.scene-transition h1 {
    margin-bottom: 3.7rem; }
  section.space div.scene.scene-intro p, section.space div.scene.scene-transition p {
    font-size: 2rem; }
  section.space div.scene.scene-intro button, section.space div.scene.scene-transition button {
    border: 1px solid #FFF;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 1rem 4rem;
    display: table;
    vertical-align: middle;
    pointer-events: all;
    cursor: pointer;
    font-size: 2rem;
    color: #FFF;
    margin: 6rem auto;
    margin-bottom: 2rem;
    transition: all 0.4s ease-in-out; }
    section.space div.scene.scene-intro button:hover, section.space div.scene.scene-transition button:hover {
      background-color: #FFF;
      color: #000; }
  section.space div.scene.scene-intro svg, section.space div.scene.scene-transition svg {
    display: inline-block;
    margin: 0 .5rem;
    width: 2.4rem;
    height: 5.5rem;
    vertical-align: middle;
    margin-bottom: .2rem; }
  section.space div.scene.scene-intro span, section.space div.scene.scene-transition span {
    display: inline-block;
    margin-top: 1.5rem; }

section.space div.scene.scene-intro span {
  margin-left: 2.3rem;
  margin-top: 0; }

section.space div.scene.scene-transition {
  background-color: transparent; }
  section.space div.scene.scene-transition p {
    display: none; }
  section.space div.scene.scene-transition svg {
    display: block;
    margin: 0 auto;
    width: 2.4rem;
    height: 5.5rem;
    vertical-align: middle;
    margin-bottom: 0; }
  section.space div.scene.scene-transition span {
    display: block;
    margin-top: 1.5rem; }

section.space div.scene div.mask-container svg > rect {
  fill: black; }

section.space div.scene div.mask-container svg mask rect {
  fill: white; }

section.space div.scene div.mask-container svg mask text {
  font-family: "raleway", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-rendering: geometricPrecision; }

section.copy h1 {
  margin-bottom: 4rem; }

section.copy h2 {
  margin-bottom: 3.2rem; }

section.copy h3 {
  margin-bottom: 3.2rem; }

section.copy h4 {
  margin-bottom: 1.5rem; }

section.copy p + h3 {
  margin-top: 3.6rem; }

section.copy p + p {
  margin-top: 3rem; }

section.copy p + a {
  margin-top: 3rem; }

section.copy .textlink {
  position: relative;
  display: inline-block;
  font-weight: 900; }
  section.copy .textlink div.link-arrow svg path {
    stroke: #000; }

section.copy div.linklist {
  margin-top: 6rem; }
  section.copy div.linklist a {
    display: block;
    position: relative;
    line-height: 1.3;
    padding-left: 2rem; }
    section.copy div.linklist a div.link-arrow {
      position: absolute;
      left: 0;
      top: .2rem; }
  section.copy div.linklist a + a, section.copy div.linklist span + a, section.copy div.linklist a + span {
    margin-top: 1rem; }
  section.copy div.linklist span {
    display: block;
    font-weight: 900;
    padding-left: 2rem;
    line-height: 1.3; }
  @media screen and (max-width: 1023px) {
    section.copy div.linklist {
      margin-top: 3rem; }
      section.copy div.linklist a + a {
        margin-top: 1.5rem; } }

section.copy div.iconlist {
  margin-top: 6rem; }
  section.copy div.iconlist > div {
    position: relative;
    padding-left: 2rem;
    line-height: 1.3;
    margin-top: 1rem; }
    section.copy div.iconlist > div:first-child {
      margin-top: 0; }
  section.copy div.iconlist div.link-arrow {
    position: absolute;
    left: 0;
    top: .2rem; }
    section.copy div.iconlist div.link-arrow svg path {
      stroke: #000;
      stroke-width: 2; }
  section.copy div.iconlist a {
    display: inline-block;
    line-height: 1.3;
    font-weight: 900; }
  section.copy div.iconlist span {
    display: block;
    font-weight: 900;
    padding-left: 2rem;
    line-height: 1.3; }
  @media screen and (max-width: 1023px) {
    section.copy div.iconlist {
      margin-top: 3rem; }
      section.copy div.iconlist > div {
        font-size: 1.5rem; } }

section.copy .round {
  border-radius: 100%; }

section.copy.lead p {
  font-size: 2rem; }

section.copy.lead div.column-content:nth-child(n+2) {
  display: none; }

section.copy div.content-padding div.split-rows.split-rows-4.projectlead {
  margin-top: 7.5rem;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column div.square-maxwidth {
    margin-bottom: 4rem; }
  section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column div.text {
    padding: 0 2rem; }
  section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column h3 {
    margin-bottom: 2.5rem; }
  @media screen and (max-width: 1023px) {
    section.copy div.content-padding div.split-rows.split-rows-4.projectlead {
      margin-top: 3rem; }
      section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column {
        margin-bottom: 2rem; }
        section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column div.square-maxwidth {
          margin-bottom: 0rem; }
        section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column h3 {
          margin-bottom: 1rem; }
        section.copy div.content-padding div.split-rows.split-rows-4.projectlead div.column:last-child {
          margin-bottom: 0; } }

@media screen and (max-width: 1023px) {
  section.copy h1 {
    margin-bottom: 2rem; }
  section.copy h2 {
    margin-bottom: 1.6rem; }
  section.copy p + p {
    margin-top: 1.4rem; }
  section.copy p + a {
    margin-top: 1.4rem; }
  section.copy.lead p {
    font-size: 1.8rem; } }

section.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #FFF; }
  section.image.hero {
    height: 0;
    padding-bottom: 31% !important; }
    section.image.hero > div {
      display: none; }
  section.image.ummah {
    background-image: url(../images/background-ummah.jpg);
    background-position: center right; }
  section.image .textlink div.link-arrow svg path {
    stroke: #FFF; }
  @media screen and (max-width: 1023px) {
    section.image {
      min-height: 30rem; } }

section.border-top div.content-padding {
  padding-top: 0; }
  section.border-top div.content-padding div.border {
    width: 100%;
    height: .1rem;
    background-image: linear-gradient(to right, #515151 33%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 5px 1px;
    background-repeat: repeat-x;
    margin-bottom: 8rem;
    -ms-flex: none;
        flex: none; }
    @media screen and (max-width: 1023px) {
      section.border-top div.content-padding div.border {
        margin-bottom: 3rem; } }

section.slider + section.border-top div.border, section.image + section.border-top div.border {
  display: none; }

section.slider + section.border-top div.content-padding, section.image + section.border-top div.content-padding {
  padding-top: 8rem; }
  @media screen and (max-width: 1023px) {
    section.slider + section.border-top div.content-padding, section.image + section.border-top div.content-padding {
      padding-top: 4rem; } }

section.news div.content-padding {
  width: 80%;
  padding-top: 0; }

section.news > svg {
  position: relative;
  top: -4rem;
  margin: 0 auto;
  width: 4rem;
  height: 4rem;
  opacity: 0;
  visibility: hidden; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

section.news div.news-title-mobile {
  display: none; }

section.news div.news-item {
  position: relative;
  padding: 5rem 0;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }
  section.news div.news-item.new {
    opacity: 0; }
  section.news div.news-item div.news-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: center;
        justify-content: center; }
    section.news div.news-item div.news-container > a {
      display: block;
      width: 24%; }
      section.news div.news-item div.news-container > a img {
        width: 100%;
        height: auto; }
    section.news div.news-item div.news-container div.news-text {
      padding-left: 2rem;
      width: 76%; }
      section.news div.news-item div.news-container div.news-text p.mobile {
        display: none; }
      section.news div.news-item div.news-container div.news-text svg path {
        stroke: #000; }
  section.news div.news-item h4 {
    margin-bottom: 2.5rem; }
  section.news div.news-item h2, section.news div.news-item p {
    margin-bottom: 2.2rem; }
  section.news div.news-item div.border {
    position: absolute;
    top: 0;
    width: 100%;
    height: .1rem;
    background-image: linear-gradient(to right, #515151 33%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 5px 1px;
    background-repeat: repeat-x; }
    section.news div.news-item div.border:last-child {
      top: inherit;
      bottom: 0; }

section.news div.news-item + div.news-item div.border:first-child {
  display: none; }

@media screen and (max-width: 1023px) {
  section.news > svg {
    top: -1.5rem; }
  section.news div.content-padding {
    width: 100%;
    padding-bottom: 3rem; }
  section.news div.news-title-mobile {
    display: block;
    margin-bottom: 3rem; }
  section.news div.news-item {
    padding: 2.5rem 0; }
    section.news div.news-item h4, section.news div.news-item h2 {
      margin-bottom: 0; }
    section.news div.news-item h4 {
      margin-bottom: .7rem; }
    section.news div.news-item div.news-container > a {
      width: 35%; }
    section.news div.news-item div.news-container div.news-text {
      width: 65%; }
      section.news div.news-item div.news-container div.news-text p {
        display: none; }
        section.news div.news-item div.news-container div.news-text p.mobile {
          display: block; }
    section.news div.news-item div.news-container h2, section.news div.news-item div.news-container h4 {
      display: none; } }

section.slider {
  position: relative;
  overflow: hidden;
  padding: 0 !important; }
  section.slider div.swiper-container {
    overflow: visible;
    padding: 0 calc(4rem + 24%);
    background-color: #000; }
    section.slider div.swiper-container div.swiper-wrapper div.swiper-slide {
      transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0.4; }
      section.slider div.swiper-container div.swiper-wrapper div.swiper-slide.swiper-slide-active {
        opacity: 1; }
    section.slider div.swiper-container div.swiper-button-prev, section.slider div.swiper-container div.swiper-button-next {
      background: none;
      width: 6rem;
      height: 12rem;
      margin-top: 0;
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
    section.slider div.swiper-container div.swiper-button-prev {
      left: calc(4rem + 4%); }
    section.slider div.swiper-container div.swiper-button-next {
      right: calc(4rem + 4%); }
    section.slider div.swiper-container div.swiper-pagination-bullets {
      bottom: 2rem; }
    section.slider div.swiper-container .swiper-pagination-bullet {
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: #FFF;
      opacity: 0.4;
      width: 1rem;
      height: 1rem; }
      section.slider div.swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
        opacity: 1; }
    @media screen and (max-width: 1023px) {
      section.slider div.swiper-container {
        padding: 0 calc(2rem + 4%); }
        section.slider div.swiper-container div.swiper-pagination-bullets {
          bottom: 1rem; }
        section.slider div.swiper-container div.swiper-button-prev, section.slider div.swiper-container div.swiper-button-next {
          width: 2.5rem;
          height: 5rem; }
        section.slider div.swiper-container div.swiper-button-prev {
          left: calc(2rem + 8%); }
        section.slider div.swiper-container div.swiper-button-next {
          right: calc(2rem + 8%); } }

div.mobile-navigation {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #000;
  color: #FFF; }
  div.mobile-navigation svg rect, div.mobile-navigation svg path {
    fill: #FFF; }
  div.mobile-navigation .logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 10; }
    div.mobile-navigation .logo svg {
      width: 8rem;
      height: 7.36rem; }
  div.mobile-navigation .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    div.mobile-navigation .close svg {
      width: 2.5rem;
      height: 2.5rem; }
      div.mobile-navigation .close svg path {
        stroke: #fff;
        stroke-width: 3;
        stroke-miterlimit: 10; }
  div.mobile-navigation navigation a {
    font-size: 4rem;
    font-weight: 900;
    display: block;
    margin: 0.5rem 0;
    padding: 1rem;
    padding-left: 0; }
    div.mobile-navigation navigation a.act {
      color: #000;
      background-color: #FFF;
      padding-left: 1rem; }
  div.mobile-navigation div.followus {
    text-align: center;
    margin-bottom: 2.2rem; }
  div.mobile-navigation div.bottom {
    padding-bottom: 3rem; }
  div.mobile-navigation div.social svg {
    width: 2.5rem;
    height: 2.5rem; }
  div.mobile-navigation div.social a:nth-child(2) svg {
    width: 2.3rem;
    height: 2.3rem; }
  div.mobile-navigation div.social a:nth-child(4) svg {
    width: 2.8rem;
    height: 2.3rem; }

div.content-item {
  margin: 0 auto;
  max-width: 70rem; }
  div.content-item h3 {
    margin-bottom: 1.5rem;
    font-size: 2rem; }
  div.content-item div.play {
    position: relative;
    margin-bottom: 3.5rem; }
    div.content-item div.play svg {
      position: absolute;
      top: 45%;
      left: 50%;
      width: 7rem;
      height: 7rem;
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
      cursor: pointer; }
    div.content-item div.play:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(ellipse at center, black 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.3;
      content: "";
      transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
  div.content-item h2 {
    margin-bottom: 2rem; }
  div.content-item div.mobile {
    display: none; }
  @media screen and (max-width: 1023px) {
    div.content-item {
      position: relative;
      max-width: none; }
      div.content-item div.play {
        margin-bottom: 0; }
        div.content-item div.play:after {
          top: 42%; }
        div.content-item div.play:before {
          opacity: 0.5;
          background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 130%); }
        div.content-item div.play:hover:before {
          opacity: 0.5; }
      div.content-item > h3, div.content-item > h2, div.content-item > p {
        display: none; }
      div.content-item div.mobile {
        position: absolute;
        display: block;
        z-index: 100;
        bottom: calc(2rem + 4vw);
        left: calc(2rem + 4vw); }
        div.content-item div.mobile h3 {
          margin-bottom: 0.7rem;
          font-size: 2rem; }
        div.content-item div.mobile h2 {
          margin-bottom: 0.5rem;
          font-size: 4rem; }
        div.content-item div.mobile div.textlink {
          font-weight: 900;
          font-size: 1.8rem; } }
  @media screen and (max-width: 768px) {
    div.content-item div.mobile {
      bottom: calc(2rem + 3vw);
      left: calc(2rem + 3vw); } }
  @media screen and (max-width: 600px) {
    div.content-item div.mobile {
      bottom: calc(2rem + 0.5vw);
      left: calc(2rem + 0.5vw); }
      div.content-item div.mobile h3 {
        margin-bottom: 0.7rem;
        font-size: 1.8rem; }
      div.content-item div.mobile h2 {
        margin-bottom: 0.2rem;
        font-size: 3rem; }
      div.content-item div.mobile div.textlink {
        font-size: 1.8rem; } }
  @media screen and (max-width: 468px) {
    div.content-item div.mobile {
      bottom: calc(2rem);
      left: calc(2rem); }
      div.content-item div.mobile h3 {
        margin-bottom: 0.4rem;
        font-size: 1.5rem; }
      div.content-item div.mobile h2 {
        margin-bottom: 0.1rem;
        font-size: 2rem; }
      div.content-item div.mobile div.textlink {
        font-size: 1.4rem; } }

div.button {
  position: relative;
  display: inline-block; }
  div.button div.link-arrow {
    position: relative;
    top: .1rem;
    display: inline-block;
    margin-right: .4rem; }
    div.button div.link-arrow svg {
      width: 1.2rem;
      height: 1.2rem; }
      div.button div.link-arrow svg path {
        stroke: #FFF; }

/********************************************************
*   COLORS
*******************************/
html {
  background-color: #FFF; }
  html body {
    background-color: #FFF;
    color: #000; }
    html body header {
      border-bottom: 0.1rem solid #000; }
      html body header.no-border {
        border: none; }
    html body footer {
      border-top: 0.1rem solid #000; }
  html.presentation {
    background-color: #000; }
    html.presentation body {
      background-color: #000;
      color: #FFF; }
      html.presentation body header {
        border-bottom: none; }
        html.presentation body header svg path {
          fill: #FFF; }
        html.presentation body header div.close svg path {
          stroke: #fff;
          stroke-width: 3;
          stroke-miterlimit: 10; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: auto; }
  .lds-ellipsis div {
    position: absolute;
    top: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    animation-timing-function: cubic-bezier(0, 1, 1, 0); }
    .lds-ellipsis div:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite; }
    .lds-ellipsis div:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite; }
    .lds-ellipsis div:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite; }
    .lds-ellipsis div:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(19px, 0); } }
