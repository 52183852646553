
/********************************************************
*   STRUCTURE
*******************************/

html{

    width: 100%;

    body{

        width: 100%;

        &.noscroll{
            //overflow: hidden;
        }

        &.toggle-navigation{

            div.mobile-navigation{
                display: block;
            }
            main{
                display: none;
            }

        }

        &.toggle-videoplayer{
            header{
                div.menu{
                    display: none;
                }
                div.close{
                    display: block;
                }
            }
            section.overlay{
                display: none !important;
            }
            section.video-player{
                display: block !important;
            }
            section.ui{
                display: flex !important;
                height: 100vh;
                position: absolute;
                pointer-events: none;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                div.controls{
                    opacity: 1;
                }
            }
        }

        &.toggle-life{
            header{
                div.menu{
                    display: none;
                }
                div.close{
                    display: block;
                }
            }

            section.overlay{
                div.content-wrapper{
                    position: relative;
                    overflow: hidden;
                }
                div.overlay-stories{
                    position: absolute;
                    display: block;
                    z-index: 0;
                    filter: blur(3rem);
                }
                div.overlay-life{
                    display: block;
                }
            }

        }

    }

    &.presentation{

        height: 100%;
        background-color: $c-black;
        overflow:hidden;

        body{
            height: 100%;
            overflow:hidden;
            background-color: $c-black;
            header{
                position: absolute;
                width: calc(100% - 8rem);
                top: 0;
                left: 0;
                z-index: 5;
                padding-bottom: 0;
            }
            main{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                z-index: 0;
                overflow: hidden;

                section{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left:0;

                    padding:0;

                    &.overlay{
                        perspective: $perspective;
                        z-index:15;
                        visibility: hidden;
                        opacity: 0;
                        pointer-events:none;
                    }
                    &.ui{
                        z-index:10;
                    }
                    &.space{
                        perspective: $perspective;
                        z-index:5;
                    }
                    &.video-player{
                        perspective: $perspective;
                        z-index:0;
                    }

                    &.enable-interaction{
                        pointer-events: all;
                    }
                }

            }
        }

        @include tablet-large-down{
            height: auto;
            overflow: auto;
            body{
                height: auto;
                overflow: auto;
                header{
                    //position: fixed;
                    width: calc(100% - 4rem);
                    z-index: 50;
                }
                main{
                    position: static;
                    height: auto;
                    section{
                        position: static;

                        &.overlay{
                            visibility: visible !important;
                            opacity: 1 !important;
                            pointer-events: all;

                            div.content-wrapper{
                                max-width: none;
                                transform:none !important;
                            }
                            div.button.close{
                                display: none;
                            }
                        }
                        &.space{

                        }
                        &.video-player{
                            height: 100vh;
                            //min-height: 50rem;
                        }
                        &.ui, &.space, &.video-player{
                            display: none;
                        }

                    }
                }
            }
        }

    }

    &.content{
        body{
            main{
                section{
                    padding:0 4rem;

                    @include tablet-large-down{
                        padding:0 2rem;
                    }
                }
            }
        }
    }

}

header{

    padding:4rem 0rem;
    margin: 0 4rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content:space-between;
    align-items: flex-start;

    navigation{
        display: flex;
        align-items:center;
    }

    @include tablet-large-down{
        padding:2rem 0rem;
        margin: 0 2rem;
        pointer-events: none;
        navigation{
            display: block;
            visibility: visible !important;
            opacity: 1 !important;
            transform: none !important;
            a{
                display: none;
            }
        }
    }

}

section{

    div.content-padding{
        padding:8rem 8%;
        @include tablet-large-down{
            padding:3rem 4%;
        }
        @include tablet-large-up{
            //padding:8rem 4%;
        }
    }

    &.overlay{

        //visibility: visible !important;
        //opacity: 1 !important;

        div.content-wrapper{

            width: 100%;
            max-width: 76%;

            div.swiper-container {

                width: 100%;
                overflow:visible;

                div.swiper-notification{
                    display: none;
                }

                div.swiper-slide{
                    padding:0 1.7%;
                    opacity: 0.4;
                    transition: opacity 0.4s $ease-power2;

                    &.swiper-slide-active{
                        cursor: pointer;
                    }

                    h3{
                        //padding:.8rem;
                        //color: $c-white;
                        //background-color:$c-black;
                        //transition: all 0.4s $ease-power2;
                    }
                    div.play{
                        //box-sizing: border-box;
                        //border: .5rem solid transparent;
                        //transition: all 0.4s $ease-power2;

                    }
                    &.swiper-slide-active{
                        opacity: 1;
                        //
                        //h3{
                        //    padding:.8rem;
                        //    color: $c-black;
                        //    background-color:$c-white;
                        //}
                        //div.play{
                        //        border: .5rem solid $c-white;
                        //    }
                    }
                }

                .swiper-pagination-bullets{
                    bottom:0;
                }

            }
        }

        div.overlay-content{
            position: relative;
            display: none;
            width:100%;
            &.overlay-stories{
                display: block;
            }
            &.overlay-life{
                margin-top: -3rem;

                div.graph{
                    > div.tooltip-template{
                        display: none;
                    }
                }

            }
        }

        div.button.close{
            svg{
                width: 6rem;
                height: 6rem;
            }
        }

        div.swiper-button-prev{
            left: auto;
            right: 100%;
            //margin-right:-2rem;
            transform:translateY(-50%);
        }

        div.swiper-button-next{
            right: auto;
            left: 100%;
            //margin-left:-2rem;
            transform:translateY(-50%);
        }

        @include tablet-large-down{

            div.content-wrapper{

                div.swiper-container{
                    overflow: auto;
                    div.swiper-wrapper{
                        display: block;
                        height: auto;
                        transform:none !important;
                        div.swiper-slide{
                            padding:0;
                            width: 100% !important;
                            opacity: 1;
                            //div.play{
                            //    border:none;
                            //}
                            //h3{
                            //    color:$c-white;
                            //    padding:0;
                            //    background-color: transparent;
                            //}
                        }
                    }
                    div.fade, div.swiper-pagination{
                        display: none;
                    }
                }

                div.swiper-button-prev, div.swiper-button-next{
                    display: none;
                }
                div.overlay-content{

                    &.overlay-life{
                        margin-top:0;
                        padding:4rem 2rem;
                        padding-top: 12rem;
                        background-color: rgba(0,0,0,0.4);

                        div.graph{
                            > div.tooltip-template{
                                display: flex;
                            }
                        }

                        h2{
                            font-size: 4rem;
                            line-height: 1;
                        }
                    }
                }

                div.close{
                    display: block;
                }

            }

        }

    }

    &.ui{

        div.button{
            position: absolute;
            z-index:1000;
            top:50%;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
        }

        div.controls {
            width:100%;
            position:absolute;
            bottom: 4rem;
            z-index:1000;
            opacity:0;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;
        }

        div.buffering{
            position: absolute;
            bottom:3rem;
            left:50%;
            transform: translateX(-50%);
            z-index:500;
            //opacity:0;
            //visibility: hidden;
        }

        div.mobileplaceholder{
            display: none;
        }

        @include tablet-large-down{
            div.button{
                visibility: hidden !important;
                opacity: 0 !important;
            }
            div.controls {
                position: static;
                bottom: -2rem;
                padding: 0 2rem !important;
                visibility: visible !important;
                opacity: 1 !important;
                pointer-events: all;
            }
            div.buffering{
                bottom:3rem;
            }
            div.mobileplaceholder{
                display: block;
                width: 100%;
                height: 56.25vw;
                margin-top:-1.2rem;
                margin-bottom: -4rem;
            }
        }

    }

    &.space{

        div.scene{

            position: absolute;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            will-change: transform;
            backface-visibility: hidden;
            pointer-events: none;
            z-index: 5;

            visibility: hidden;
            opacity: 0;

            &.scene-intro{
                visibility: visible;
                opacity: 1;
                z-index: 10;
                perspective: $perspective;
                div.content-wrapper{
                    margin-top: 5vh;
                }
            }

            &.scene-transition{
                div.content-wrapper{
                    margin-top: 70vh;
                }
            }

            div.content-wrapper{
                max-width: 64rem;
                text-align: center;
            }

            div.image-container{
                width:100%;
                height:100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            div.mask-container{

                width:100%;
                height:100%;

                display: flex;
                align-items: center;
                justify-content: center;

                position: relative;

                img{
                    width: 50%;
                    height: auto;
                }

                svg {
                    user-select: none;
                    margin:0 auto;
                    width: 100%;
                    height: auto;
                }

            }

        }

    }

    &.video-player{

        top:0;
        left:0;
        width: 100%;
        height: 100%;

        video{
            width: 100%;
            height: 100%;
        }

        div.dimm{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 50;
            background-color: $c-black;
        }

        div.mask{

            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            z-index:100;

            background-color: rgba(0,0,0,1);

            &.top{
                top:-100%;
            }
            &.left{
                left:-100%;
            }
        }

        div.vertex{
            position: absolute;
            top:50%;
            left: 50%;
            width: 10px;
            height: 10px;
            visibility: hidden;
            pointer-events: none;
            background-color: green;
            z-index:10000;
        }

        @include tablet-large-down{
            z-index: 1000;

            video{
                width:100% !important;
                height: auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%) !important;
            }
            div.dimm, div.mask, div.vertex{
                display: none;
            }
        }

    }

    &.copy{

        div.content-padding{

            //max-width: 180rem; needs a div constrain
            margin: 0 auto;

            &.split-rows, div.split-rows{
                display: flex;
                align-items: flex-start;
                align-content:stretch;
                width: 100%;
                flex-wrap: wrap;

                div.column{
                    margin-top:6rem;
                    &:nth-child(-n+4){
                        margin-top: 0;
                    }
                }

                div.column-content{
                    width: 48%;
                }
                div.column-space{
                    width: 10%;
                    //max-width: 22rem;
                }
                &.split-rows-4{
                    div.column{
                        width: 23%;
                        flex: none;
                        div.square-maxwidth{
                            width: 65%;
                            margin:0 auto;
                        }
                    }
                }
            }

            @include tablet-large-down{
                &.split-rows, div.split-rows{
                    display:block;
                    div.column-content{
                        width: 100%;

                    }
                    div.column-content + div.column-content, div.column-space + div.column-content{
                        margin-top:3rem;
                    }
                    div.column-space{
                        width: 0;
                    }
                    &.split-rows-4{
                        div.column{
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            align-content:stretch;
                            div.square-maxwidth{
                                width: 34%;
                                max-width: 18rem;
                            }
                            div:nth-child(2){
                                width: 66%;
                                padding-left: 2rem;
                            }
                        }
                    }
                }
            }
        }

        &.centered{
            text-align: center;
            div.content-padding{
                text-align: center;
            }
            div.centered{
                max-width: 84rem;
                margin:0 auto;
            }
            div.split-rows{
                max-width: 120rem;
                margin:0 auto;
            }
            @include tablet-large-down{
                text-align: left;
                div.content-padding{
                    text-align: left;
                }
                div.centered{
                    max-width: 100%;
                }
                div.split-rows{
                    max-width: 100%;
                }
            }
        }

    }

    &.image{
        div.content-padding{
            padding-top: 12rem;
            padding-bottom: 18rem;
            @include tablet-large-down{
                padding-top: 3rem;
                padding-bottom: 6rem;
            }
        }
    }

}

footer{

    padding:4rem 0;
    margin: 0 4rem;
    padding-bottom: 9rem;
    display: flex;
    justify-content:space-between;
    align-items: flex-start;

    div.followus{
        visibility: hidden;
        text-align: center;
    }

    navigation{

        display: flex;
        align-items:center;

        div.menu{
            display: none !important;
        }

    }

    @include tablet-large-down{
        display: block;
        padding:2rem 0;
        padding-bottom: 3rem;
        div.followus{
            visibility: visible;
        }
        navigation{
            max-width: 40rem;
            margin: 0 auto;
            margin-top: 1.5rem;
            justify-content: space-around;
            a{
                margin-left: 0 !important;
            }
        }
    }

}

div.mobile-navigation{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    min-height: 50rem;
    z-index: 10000;
    navigation{
        padding:10rem calc(2rem + 4%);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content:center;
        flex-direction: column;
        a{
            color: $c-white;
        }
    }
    div.bottom{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    div.social{
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 30rem;
        margin:0 auto;
    }
}
