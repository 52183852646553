
/* -414 */
@mixin mobile-large-down{
	@media screen and (max-width: 468px) { @content; }
}

/* -768 */
@mixin mobile-xtra-large-down{
	@media screen and (max-width: 600px) { @content; }
}

/* -768 */
@mixin tablet-narrow-down{
	@media screen and (max-width: 768px) { @content; }
}

/* -1024 */
@mixin tablet-large-down{
	@media screen and (max-width: 1023px) { @content; }
}

/* -1280 */
@mixin desktop-narrow-down{
	@media screen and (max-width: 1280px) { @content; }
}

/* -1920 */
@mixin desktop-default-down{
	@media screen and (max-width: 1920px) { @content; }
}

/* -2,5k */
@mixin desktop-large-down{
	@media screen and (max-width: 2542px) { @content; }
}

/* -4k */
@mixin desktop-ultralarge-down{
	@media screen and (max-width: 3824px) { @content; }
}


/* 414+ */
@mixin mobile-large-up{
	@media screen and (min-width: 414px) { @content; }
}

/* 768+ */
@mixin tablet-narrow-up{
	@media screen and (min-width: 769px) { @content; }
}

/* 1024+ */
@mixin tablet-large-up{
	@media screen and (min-width: 1024px) { @content; }
}

/* 1280+ */
@mixin desktop-narrow-up{
	@media screen and (min-width: 1281px) { @content; }
}

/* 1920+ */
@mixin desktop-default-up{
	@media screen and (min-width: 1921px) { @content; }
}

/* 2,5k+ */
@mixin desktop-large-up{
	@media screen and (min-width: 2543px) { @content; }
}

/* 4k+ */
@mixin desktop-ultralarge-up{
	@media screen and (min-width: 3824px) { @content; }
}
