
.tippy-popper{
    //margin-top:-4rem;
}

.tippy-tooltip.sms-theme {

    background-color: $c-white;
    border: none;
    border-radius: 0;
    max-width: 60rem;
    padding: 3rem;
    box-shadow: .5rem .5rem 3rem .5rem rgba(0,0,0,0.3);

    div.tippy-content{
        div.tooltip-template{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            div.text-content{
                width: 60%;
                color: $c-black;
                text-align: left;
                padding-right: 1.5rem;
                &.only{
                    padding:0;
                    width:100%;
                    max-width: 45rem;
                }
            }
            img{
                width: 40%;
                max-width: 25rem;
            }
            h3{
                font-size: 2.8rem;
                margin:1.2rem 0;
                margin-bottom:1.5rem;
            }
            h4{
                //text-transform: none;
            }
            p{
                font-size: 1.6rem;
            }
        }
    }

    div.tippy-arrow{
        transform: rotate(-90deg);
        bottom: -3rem;
        //margin-left: -2rem !important;
    }

}

.tippy-popper[x-placement^=top] .tippy-tooltip.sms-theme .tippy-arrow {
    border-top: 3rem solid $c-white;
    border-right: 3rem solid transparent;
    border-left: 3rem solid transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.sms-theme .tippy-arrow {
    border-top: 3rem solid $c-white;
    border-right: 3rem solid transparent;
    border-left: 3rem solid transparent;
    border-bottom: none;
    bottom: inherit;
    top:-3rem;
    transform: rotate(-90deg) translateY(100%) !important;
}
