/********************************************************
*   FONTS
*******************************/
h1{
    font-family: $font1;
    font-size: 8rem;
    font-weight: $weight-black;
    line-height: 1.05;
    display: block;
}

h2{
    font-family: $font1;
    font-size: 4rem;
    font-weight: $weight-black;
    line-height: 1.05;
    display: block;
}

h3{
    font-family: $font1;
    font-size: 2.4rem;
    font-weight: $weight-black;
    line-height: 1.05;
    display: inline-block;
}

h4{
    font-family: $font1;
    font-size: 1.6rem;
    font-weight: $weight-black;
    line-height: 1.3;
    display: block;
}

h1, h2, h3, h4{
    span{
        font-size:inherit;
    }
    word-break: break-word;
    hyphens: auto;
}

a{

    font-family: $font1;
    line-height: 1;
    text-decoration:none;
    color: inherit;
    transition: color 200ms ease-out;

    &.act, &:hover{
        text-decoration: none;
    }

    &.textlink{
        position: relative;
        display: inline-block;
        font-weight: $weight-black;
        div.link-arrow{
            position: relative;
            top: .1rem;
            display: inline-block;
            margin-right: .5rem;
        }
    }

}

p{

    font-family: $font1;
    font-size: 1.6rem;
    line-height: 1.5;

    &.lead{
        font-size: 2rem;
    }

    &.bold{
        font-family: $font1;
    }

    strong{
        font-weight: $weight-bold;
    }
    em{
        font-style: italic;
    }

    &.title{
        text-decoration: underline;
    }

    a{
        transition: border 0.4s $ease-power2;
        border-bottom: 1px solid $c-grey-dark;
        &:hover{
            border-bottom: 1px solid $c-grey;
        }
    }

    span{
        &.italic{
            font-style: italic;
        }
        &.bold{
            font-family: $font1;
        }
        &.smaller{
            margin-top:1rem;
            font-size: 1.1rem;
            display: block;
        }
    }

    &:last-child{
        margin-bottom:0;
    }

}

span{
    font-family: $font1;
    font-size: 1.6rem;
    line-height: 1.4;
}

ul{
    li{

        font-family: $font1;
        font-size: 1rem;
        line-height: 1.5;
    }
}

@include tablet-large-down{

    h1{
        font-size: 4rem;
    }

    h2{
        font-size: 2.5rem;
    }

    h3{
        font-size: 2rem;
    }
    h4{
        font-size: 1.5rem;
    }

    a{
        font-size: 1.5rem;
    }

    p{
        font-size: 1.5rem;
        &.lead{
            font-size: 1.8rem;
        }
    }


}
