/********************************************************
*   STYLED CHECK & RADIO
*******************************/
label.styled{
    
    position:relative;
    padding-left: 3rem;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 2rem;
    
    &:last-child{
        margin-bottom:0;
    }

    &.error, &.has-danger, &.error a, &.error span{
    	color:#f00;
    }

    span{
    	display: inline-block;
    	padding-left: 0px;
  		text-align: left;
        font-size: 1.2rem;
        line-height: 1.7;

    	a{
		    text-decoration: underline;
		    &:hover{

		    }
    	}

    }

    img, i{
    	position: absolute;
	    top: 0rem;
	    left:0rem;
	    opacity:0;
	    transition: opacity 200ms $ease;
	    pointer-events:none;
        width: 2rem;
        height: 2rem;

    	&.on{
	    	opacity:0;
	    }
	    &.off{
	    	opacity:1;
	    }

    }

    &.checked{
    	img, i{
    		&.off{
    			opacity:0;
    		}
    		&.on{
    			opacity:1;
    		}
    	}
    }

    input{
    	position: absolute;
    	left: -9999px;
    }

}